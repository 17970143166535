import React from "react"
import { graphql } from "gatsby"
import { FiLinkedin, FiInstagram, FiTwitter, FiMail } from "react-icons/fi"
import Fade from "react-reveal/Fade"
// Components
import Layout from "../components/layout"
import SEO from "../util/SEO"
// Styles
import styles from "./styles/index.module.scss"

const index = ({ data }) => {
  const siteUrl = data.site.siteMetadata.siteUrl

  return (
    <>
      <SEO title="" description="" canonical={siteUrl} />
      <Layout>
        <div className={styles.info}>
          <Fade delay={300} duration={3000}>
            <h1 className={styles.brand}>
              <span className={styles.calla}>Calla</span>
              <span className={styles.nyc}>NYC</span>
            </h1>
          </Fade>
          <Fade delay={600} duration={3000}>
            <p className={styles.comingSoon}>
              Coming soon <span className={styles.blink1}>.</span>{" "}
              <span className={styles.blink2}>.</span>{" "}
              <span className={styles.blink3}>.</span>{" "}
            </p>
          </Fade>
          <Fade delay={900} duration={3000}>
            <p className={styles.bio}>
              Calla is a Copywriter born and raised in New York living in
              Manhattan
            </p>
          </Fade>
          <ul className="reset no-list">
            <Fade delay={1000} duration={1000}>
              <li>
                <a
                  className={styles.linkedin}
                  href="https://www.linkedin.com/in/callas-t/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FiLinkedin />
                </a>
              </li>
            </Fade>
            <Fade delay={1500} duration={1000}>
              <li>
                <a
                  className={styles.instagram}
                  href="https://www.instagram.com/gingerhead9/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FiInstagram />
                </a>
              </li>
            </Fade>
            <Fade delay={2000} duration={1000}>
              <li>
                <a
                  className={styles.twitter}
                  href="https://twitter.com/gingerhead9"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FiTwitter />
                </a>
              </li>
            </Fade>

            <Fade delay={2500} duration={1000}>
              <li>
                <a
                  className={styles.mail}
                  href="mailto:contact@calla.nyc"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FiMail />
                </a>
              </li>
            </Fade>
          </ul>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default index
